<template>
  <div>
    <v-container v-if="isResponsive" fluid>
      <v-row>
        <v-col cols="12">
          <div class="display-flex align-items-center mt-5">
            <div
              @click="$router.push({ name: 'Article' })"
              style="width: fit-content"
              class="cursor-pointer display-flex align-items-center"
            >
              <v-btn class="button-return-icon" icon>
                <v-icon color="#283C4D" size="16px"> mdi-arrow-left </v-icon>
              </v-btn>
              <p class="button-return-text mon-regular hover-underline ml-2">
                {{ texts.articledetail.textButtonReturn }}
              </p>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              v-if="activeArticle"
              elevation="0"
              class="button-play mon-semibold"
              @click="changeStatusArticle(false)"
              :loading="bLoading"
            >
              {{ texts.articledetail.play }}
              <v-icon size="14px" class="ml-1"> mdi-play </v-icon>
            </v-btn>
            <v-btn v-else elevation="0" class="button-pause mon-semibold" @click="changeStatusArticle(true)" :loading="bLoading">
              {{ texts.articledetail.pause }}
              <v-icon size="14px" class="ml-1"> mdi-pause </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="display-flex align-items-center justify-content-center">
            <p class="text-title mon-bold">{{ productName }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <div v-if="lengthImages > 0">
              <div class="display-flex resize-content">
                <div class="content-resize-image" style="width: 100%;">
                  <!-- <zoom-image-component :imageSelected="imageSelected" /> -->
                  <img width="100%" height="100%" :src="imageSelected" />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="content-image-empty">
                <img src="@/assets/images/placeholder-image.jpeg" alt="" />
              </div>
            </div>
            <div class="display-flex align-items-center" style="overflow-x: auto;">
              <div v-for="(item, index) in aArticle.aImages" :key="index" class="content-fixed-image-responsive">
                <div style="width: 120px;">
                  <div @click="selectImage(item)" class="content-image cursor-pointer">
                    <img :src="item.oImages.md" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <p style="text-align: center;" class="text-location mon-bold">
              <v-icon size="18px" color="#9AA6B1"> mdi-map-marker </v-icon>
              {{ aArticle.sLocationWarehouse }}
            </p>
            <div class="display-flex align-items-center justify-content-center mb-4">
              <v-rating :value="aArticle.dRating" length="5" color="#FFC92A" background-color="#C6C6C8" dense small readonly></v-rating>
              <p :style="aArticle.dRating == 0 ? { color: '#C6C6C8' } : {}" class="text-calif-num mon-bold ml-1 mb-n1">
                {{ aArticle.dRating > 5 ? 5 : aArticle.dRating }}
              </p>
            </div>
            <div class="display-flex align-items-center border-top-line border-bottom-line pt-2 pb-2">
              <p style="font-size: 16px;" class="text-price mon-regular">{{ textPrice() }}</p>
              <v-spacer></v-spacer>
              <p style="font-size: 16px;" class="text-number-price mon-bold">${{ formatMoney(aArticle.dUnitPrice) }} MXN</p>
            </div>
            <div class="card-content-about mt-3">
              <p class="text-title-about-article mon-bold">
                {{ texts.articledetail.textTitleAboutArticle }}
              </p>
              <p class="text-title-about-description-article mon-regular">
                {{ productDescription }}
              </p>
            </div>
            <div class="card-content-info mt-3">
              <!-- 8 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-map-marker </v-icon>
                  {{ texts.articledetail.description.text8 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.sLocationWarehouse }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <!-- 7 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px">
                    mdi-calendar-outline
                  </v-icon>
                  {{ texts.articledetail.description.text7 }}
                </p>
                <v-spacer></v-spacer>
                <!-- <p class="description-text mon-regular">{{ aArticle.tReadyToShip }}</p> -->
                <p class="description-text mon-regular">
                  {{ aArticle.tProductAvailableTime }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-link-variant </v-icon>
                  {{ selectLanguage == "sp" ? "Tabulador de fletes" : "Freight table" }}
                </p>
                <v-spacer></v-spacer>
                <p @click="redirectLink" class="link-hover description-text mon-regular">
                  {{ selectLanguage == "sp" ? "Descargar" : "Download" }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <!-- 1 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px">
                    mdi-format-list-numbered
                  </v-icon>
                  {{ texts.articledetail.description.text1 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.dInventoryQuantity }} {{ nameBox }}</p>
              </div>
              <div class="border-bottom-line" />
              <!-- 3 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                  {{ texts.articledetail.description.text13 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.dMinAmountAllowed }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <!-- 12 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-weight </v-icon>
                  {{ text12() }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ weightBox }} LBS</p>
              </div>
              <div class="border-bottom-line" />
              <!-- 11 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                  {{ text11() }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ piecesPallet }}</p>
              </div>
              <div class="border-bottom-line" />
              <!-- 10 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px">
                    mdi-package-variant
                  </v-icon>
                  {{ text10() }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ boxesPallet }} {{ nameBox }}</p>
              </div>
              <div class="border-bottom-line" />
              <!-- 3 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-thermometer </v-icon>
                  {{ texts.articledetail.description.text3 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.dStorageTemperature }} F°</p>
              </div>
              <div class="border-bottom-line" />
              <!-- 4 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-thermometer </v-icon>
                  {{ texts.articledetail.description.text4 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.dTransportationTemperature }} F°</p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-near-me </v-icon>
                  {{ selectLanguage == "sp" ? "País" : "Country" }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.sEnterpriseCountryName }}</p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-map-marker </v-icon>
                  {{ selectLanguage == "sp" ? "Estado" : "State" }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.sLocationWarehouse }}</p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                  {{ selectLanguage == "sp" ? "Número de lote" : "Lot number" }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.sLotId }}</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-if="!showButton" class="content-buttons-desktop border-top-line display-flex align-items-center pt-5 mb-9">
            <v-btn @click="deleteArticle" elevation="0" class="button-tertiary mon-regular">
              <v-icon color="#F50017" size="16px"> mdi-delete </v-icon>
              {{ texts.articledetail.buttonDelete }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="$router.push({ name: 'Article' })" elevation="0" class="button-secondary mon-regular">
              {{ texts.articledetail.buttonReturn }}
            </v-btn>
            <v-btn @click="modifyInventory" elevation="0" class="button-secondary mon-regular ml-2">
              {{ texts.articledetail.modifyInventory }}
            </v-btn>
            <v-btn @click="editArticle" elevation="0" class="button-primary mon-bold ml-2">
              {{ texts.articledetail.buttonModify }}
              <v-icon color="#283C4D" size="16px"> mdi-pencil </v-icon>
            </v-btn>
          </div>
          <div v-else>
            <v-btn @click="editArticle" elevation="0" class="button-primary mon-bold mb-5" width="100%">
              {{ texts.articledetail.buttonModify }}
              <v-icon color="#283C4D" size="16px"> mdi-pencil </v-icon>
            </v-btn>
            <v-btn @click="modifyInventory" elevation="0" class="button-secondary mon-regular mb-5" width="100%">
              {{ texts.articledetail.modifyInventory }}
            </v-btn>
            <v-btn @click="$router.push({ name: 'Article' })" elevation="0" class="button-secondary mon-regular mb-5" width="100%">
              {{ texts.articledetail.buttonReturn }}
            </v-btn>
            <v-btn @click="deleteArticle" elevation="0" class="button-tertiary mon-regular mb-5" width="100%">
              <v-icon color="#F50017" size="16px"> mdi-delete </v-icon>
              {{ texts.articledetail.buttonDelete }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- ----
    
    
    
    
    
    
    
    
     -->

    <v-container v-else fluid>
      <v-row>
        <v-col>
          <div class="mt-1">
            <div
              @click="$router.push({ name: 'Article' })"
              style="width: fit-content"
              class="cursor-pointer display-flex align-items-center"
            >
              <v-btn class="button-return-icon" icon>
                <v-icon color="#283C4D" size="16px"> mdi-arrow-left </v-icon>
              </v-btn>
              <p class="button-return-text mon-regular hover-underline ml-2">
                {{ texts.articledetail.textButtonReturn }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="5" lg="5" md="7" sm="12">
          <div v-if="lengthImages > 0">
            <div class="display-flex resize-content">
              <div class="content-fixed-image">
                <div v-for="(item, index) in aArticle.aImages" :key="index">
                  <div @click="selectImage(item)" class="content-image cursor-pointer">
                    <img :src="item.oImages.md" alt="" />
                  </div>
                </div>
              </div>
              <div class="content-resize-image">
                <zoom-image-component :imageSelected="imageSelected" />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="content-image-empty">
              <img src="@/assets/images/placeholder-image.jpeg" alt="" />
            </div>
          </div>
          <div class="mt-2">
            <p class="text-mouse-zoom mon-medium text-center">
              {{ texts.articledetail.textMouseZoom }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" xl="7" lg="7" md="5" sm="12">
          <div class="pa-2">
            <div class="display-flex align-items-center mb-5">
              <p class="text-title mon-bold">{{ productName }}</p>
              <v-spacer />
              <v-btn
                v-if="activeArticle"
                elevation="0"
                class="button-play mon-semibold"
                @click="changeStatusArticle(false)"
                :loading="bLoading"
              >
                {{ texts.articledetail.play }}
                <v-icon size="14px" class="ml-1"> mdi-play </v-icon>
              </v-btn>
              <v-btn v-else elevation="0" class="button-pause mon-semibold" @click="changeStatusArticle(true)" :loading="bLoading">
                {{ texts.articledetail.pause }}
                <v-icon size="14px" class="ml-1"> mdi-pause </v-icon>
              </v-btn>
            </div>
            <p class="text-location mon-bold">
              <v-icon size="18px" color="#9AA6B1"> mdi-map-marker </v-icon>
              {{ aArticle.sLocationWarehouse }}
            </p>
            <div class="display-flex align-items-center mb-4">
              <v-rating :value="aArticle.dRating" length="5" color="#FFC92A" background-color="#C6C6C8" dense small readonly></v-rating>
              <p :style="aArticle.dRating == 0 ? { color: '#C6C6C8' } : {}" class="text-calif-num mon-bold ml-1 mb-n1">
                {{ aArticle.dRating > 5 ? 5 : aArticle.dRating }}
              </p>
            </div>
            <div
              class="
              display-flex
              align-items-center
              border-top-line border-bottom-line
              pt-2
              pb-2
            "
            >
              <p class="text-price mon-regular">{{ textPrice() }}</p>
              <v-spacer></v-spacer>
              <p class="text-number-price mon-bold">${{ formatMoney(aArticle.dUnitPrice) }} MXN</p>
            </div>
            <div class="card-content-about mt-3">
              <p class="text-title-about-article mon-bold">
                {{ texts.articledetail.textTitleAboutArticle }}
              </p>
              <p class="text-title-about-description-article mon-regular">
                {{ productDescription }}
              </p>
            </div>
            <div class="card-content-info mt-3">
              <!-- 8 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-map-marker </v-icon>
                  {{ texts.articledetail.description.text8 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.sLocationWarehouse }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <!-- 7 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px">
                    mdi-calendar-outline
                  </v-icon>
                  {{ texts.articledetail.description.text7 }}
                </p>
                <v-spacer></v-spacer>
                <!-- <p class="description-text mon-regular">{{ aArticle.tReadyToShip }}</p> -->
                <p class="description-text mon-regular">
                  {{ aArticle.tProductAvailableTime }}
                </p>
              </div>
              <!-- <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon
                    color="#283C4D"
                    size="18px"
                  > mdi-link-variant </v-icon>
                  {{
                  selectLanguage == "sp"
                    ? "Tabulador de fletes"
                    : "Freight table"
                }}
                </p>
                <v-spacer></v-spacer>
                <p
                  @click="redirectLink"
                  class="link-hover description-text mon-regular"
                >
                  {{ selectLanguage == "sp" ? "Descargar" : "Download" }}
                </p>
              </div> -->
              <div class="border-bottom-line" />
              <!-- 1 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px">
                    mdi-format-list-numbered
                  </v-icon>
                  {{ texts.articledetail.description.text1 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.dInventoryQuantity }} {{ nameBox }}(s)</p>
              </div>
              <div class="border-bottom-line" />
              <!-- 3 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                  {{ texts.articledetail.description.text13 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">
                  {{ aArticle.dMinAmountAllowed }}
                </p>
              </div>
              <div class="border-bottom-line" />
              <!-- 12 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-weight </v-icon>
                  {{ text12() }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ weightBox }} LBS</p>
              </div>
              <div class="border-bottom-line" />
              <!-- 11 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                  {{ text11() }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ piecesPallet }}</p>
              </div>
              <div class="border-bottom-line" />
              <!-- 10 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px">
                    mdi-package-variant
                  </v-icon>
                  {{ text10() }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ boxesPallet }} {{ nameBox }}</p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px">
                    mdi-truck
                  </v-icon>
                  {{ text13() }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.dPalletsPerTruck }}</p>
              </div>
              <div class="border-bottom-line" />
              <!-- 3 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-thermometer </v-icon>
                  {{ texts.articledetail.description.text3 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.dStorageTemperature }} F°</p>
              </div>
              <div class="border-bottom-line" />
              <!-- 4 -->
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-thermometer </v-icon>
                  {{ texts.articledetail.description.text4 }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.dTransportationTemperature }} F°</p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-near-me </v-icon>
                  {{ selectLanguage == "sp" ? "País" : "Country" }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.sEnterpriseCountryName }}</p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-map-marker </v-icon>
                  {{ selectLanguage == "sp" ? "Estado" : "State" }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.sLocationWarehouse }}</p>
              </div>
              <div class="border-bottom-line" />
              <div class="display-flex align-items-center pa-5">
                <p class="description-title mon-medium">
                  <v-icon color="#283C4D" size="18px"> mdi-counter </v-icon>
                  {{ selectLanguage == "sp" ? "Número de lote" : "Lot number" }}
                </p>
                <v-spacer></v-spacer>
                <p class="description-text mon-regular">{{ aArticle.sLotId }}</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="border-top-line display-flex align-items-center pt-5 mb-9">
            <v-btn @click="deleteArticle" elevation="0" class="button-tertiary mon-regular">
              <v-icon color="#F50017" size="16px"> mdi-delete </v-icon>
              {{ texts.articledetail.buttonDelete }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="$router.push({ name: 'Article' })" elevation="0" class="button-secondary mon-regular">
              {{ texts.articledetail.buttonReturn }}
            </v-btn>
            <v-btn @click="modifyInventory" elevation="0" class="button-secondary mon-regular ml-2">
              {{ texts.articledetail.modifyInventory }}
            </v-btn>
            <v-btn @click="editArticle" elevation="0" class="button-primary mon-bold ml-2">
              {{ texts.articledetail.buttonModify }}
              <v-icon color="#283C4D" size="16px"> mdi-pencil </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ArticleDetailContentLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      aArticle: [],
      aMeasureUnit: {},
      productName: "",
      productDescription: "",
      imageSelected: "",
      amount: 1,
      dPalletsPerTruck: 0,
      boxesPallet: 0,
      piecesPallet: 0,
      weightBox: 0,
      nameBox: "",
      lengthImages: 0,
      activeArticle: null,
      bLoading: false,
      isResponsive: false,
      showButton: false,
    };
  },
  beforeMount() {
    this.getArticles();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function() {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }

      if (window.innerWidth < 600) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    redirectLink: function() {
      let link = "https://proplat-public-images.s3.amazonaws.com/PROPLAT-TABULADOR.pdf";
      window.open(link, "blank");
    },
    textPrice: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0) {
        if (this.selectLanguage == "sp") {
          text = "Precio por " + this.nameBox;
        } else {
          text = "Price per " + this.nameBox;
        }
      }
      return this.capitalize(text);
    },
    text10: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Número de " + this.nameBox + " por pallet";
        } else {
          text = "Number of " + this.nameBox + " per pallet";
        }
      return this.capitalize(text);
    },
    text11: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Piezas por " + this.nameBox;
        } else {
          text = "Aprox pieces per " + this.nameBox;
        }
      return this.capitalize(text);
    },
    text12: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Peso por " + this.nameBox;
        } else {
          text = "Weight per " + this.nameBox;
        }
      return this.capitalize(text);
    },
    text13: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Pallets totales en camion";
        } else {
          text = "Total pallets in truck";
        }
      return this.capitalize(text);
    },
    capitalize: function(str) {
      let lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice("1");
    },
    changeStatusArticle: function(value) {
      this.bLoading = true;

      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {};

      DB.patch(`${URI}/api/v1/${this.selectLanguage}/products/${this.aArticle.sProductId}/visible/${value}`, payload, config)
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    modifyInventory: function() {
      this.$store.commit("setDialogInventory", {
        active: true,
        arr: this.aArticle,
      });
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    formatDate: function(date) {
      var new_date = MOMENT(date, "DD/MM/YYYY");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
    editArticle: function() {
      this.$router.push({
        name: "EditArticle",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    deleteArticle: function() {
      this.$store.commit("setDialogDeleteConfirm", {
        active: true,
        obj: {
          title: this.texts.articledetail.dialogDelete.textTitle,
          description: this.texts.articledetail.dialogDelete.textDescription,
          subdescription: this.texts.articledetail.dialogDelete.textSubDescription,
          confirmation: this.texts.articledetail.dialogDelete.textConfirmation,
          api: `${URI}/api/v1/${this.selectLanguage}/products/${this.aArticle.sProductId}`,
          redirect: "/articles",
        },
      });
    },
    getArticles: function() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products/vendors/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          // bCustomer: true,
          aImagesSizes: "md,lg",
        },
      })
        .then((response) => {
          let arr = response.data.results;

          this.aArticle = arr;
          this.productName = arr.aTranslations.find((res) => {
            return res.sLang == this.selectLanguage;
          }).sName;
          this.productDescription = arr.aTranslations.find((res) => {
            return res.sLang == this.selectLanguage;
          }).sDescription;
          this.lengthImages = arr.aImages.length;
          if (arr.aImages.length > 0) {
            this.imageSelected = arr.aImages[0].oImages.lg;
          }

          this.aMeasureUnit = arr.oMeasurementUnit;
          this.boxesPallet = arr.oMeasurementUnit.oData.dBoxesPerPallet;
          this.palletsPerTruck = arr.dPalletsPerTruck;
          this.piecesPallet = arr.oMeasurementUnit.oData.dPiecesPerBox;
          this.weightBox = arr.oMeasurementUnit.oData.dWeightPerBox;
          this.nameBox = arr.oMeasurementUnit.sName;
          this.activeArticle = arr.bVisible;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectImage: function(e) {
      this.imageSelected = e.oImages.lg;
    },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
    refresh: function() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.getArticles();
      }
    },
    refresh: function() {
      this.getArticles();
    },
  },
};
</script>

<style scoped>
.link-hover {
  color: #0971fa !important;
}

.link-hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-calif {
  text-align: right;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif-num {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.button-play {
  background: #17f500 0% 0% no-repeat padding-box !important;
  border-radius: 15px;
  opacity: 1;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: initial !important;
}

.button-pause {
  background: #f50017 0% 0% no-repeat padding-box !important;
  border-radius: 15px;
  opacity: 1;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: initial !important;
}

.content-image-empty {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.content-image-empty img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-empty img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
    border: 1px solid #00000029;
  }
}

.button-delete {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #f50017;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #f50017;
  opacity: 1;
  text-transform: initial;
  height: 40px !important;
  width: 130px;
}

.button-back {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  text-transform: initial;
  height: 40px !important;
  width: 130px;
}

.button-modify {
  background: transparent linear-gradient(256deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 13px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  text-transform: initial;
  height: 40px !important;
  width: 130px;
}

.hover-underline:hover {
  text-decoration: underline;
}

.button-return-icon {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  width: 25px !important;
  height: 25px !important;
}

.button-return-text {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-title {
  text-align: left;
  font-size: 25px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.text-location {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
}

.text-calification {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-reviews {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.border-top-line {
  border-top: 1px solid #c3c3c3;
}

.border-bottom-line {
  border-bottom: 1px solid #c3c3c3;
}

.text-price {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-number-price {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.card-content-about {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
}

.card-content-info {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.text-title-about-article {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
}

.text-title-about-description-article {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.description-title {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.description-text {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.content-fixed-image {
  width: 125px;
  max-height: 350px;
  overflow: auto;
  padding: 10px;
}

.content-fixed-image .content-image {
  width: 100%;
  height: 100px;
  opacity: 1;
  margin-bottom: 10px;
}

.content-fixed-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-fixed-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

.content-fixed-image-responsive {
  width: 120px;
  padding: 10px;
  margin-right: 15px;
}

.content-fixed-image-responsive .content-image {
  width: 100%;
  height: 100px;
  opacity: 1;
  margin-bottom: 10px;
}

.content-fixed-image-responsive .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-fixed-image-responsive .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

.content-resize-image {
  width: calc(100% - 125px);
  height: 350px;
  padding: 10px;
  border: 1px solid #00000029;
  border-radius: 23px;
}

.content-resize-image .content-image {
  width: 100%;
  height: 100%;
  opacity: 1;
  margin-bottom: 10px;
}

.content-resize-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-resize-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

@media (max-width: 600px) {
  .resize-content {
    display: block;
  }
  .content-fixed-image {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .content-fixed-image .content-image {
    width: 130px;
    margin: 1px;
    padding: 4px;
  }
  .content-resize-image {
    width: 100% !important;
  }
}
</style>
